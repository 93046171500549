/**
 * Do not edit directly
 * Generated on Fri, 17 Jun 2022 14:38:36 GMT
 */

:root {
  --dentsu-tracking-widest: 0.5ch;
  --dentsu-tracking-wider: 0.25ch;
  --dentsu-tracking-wide: 0.125ch;
  --dentsu-tracking-normal: 0;
  --dentsu-tracking-tight: -0.0625ch;
  --dentsu-tracking-tighter: -0.125ch;
  --dentsu-leading-loose: 2;
  --dentsu-leading-relaxed: 1.75;
  --dentsu-leading-normal: 1.5;
  --dentsu-leading-tight: 1.25;
  --dentsu-leading-none: 1;
  --dentsu-font-weight-black: 900;
  --dentsu-font-weight-extrabold: 800;
  --dentsu-font-weight-bold: 700;
  --dentsu-font-weight-semibold: 600;
  --dentsu-font-weight-medium: 500;
  --dentsu-font-weight-regular: 400;
  --dentsu-font-weight-light: 300;
  --dentsu-font-weight-extralight: 200;
  --dentsu-font-weight-thin: 100;
  --dentsu-font-style-italic: italic;
  --dentsu-font-style-regular: normal;
  --dentsu-font-size-92: 5.75rem; /* 92px */
  --dentsu-font-size-84: 5.25rem; /* 84px */
  --dentsu-font-size-76: 4.75rem; /* 76px */
  --dentsu-font-size-68: 4.25rem; /* 68px */
  --dentsu-font-size-60: 3.75rem; /* 60px */
  --dentsu-font-size-58: 3.625rem; /* 58px */
  --dentsu-font-size-54: 3.375rem; /* 54px */
  --dentsu-font-size-48: 3rem; /* 48px */
  --dentsu-font-size-44: 2.75rem; /* 44px */
  --dentsu-font-size-42: 2.625rem; /* 42px */
  --dentsu-font-size-40: 2.5rem; /* 40px */
  --dentsu-font-size-36: 2.25rem; /* 36px */
  --dentsu-font-size-32: 2rem; /* 32px */
  --dentsu-font-size-28: 1.75rem; /* 28px */
  --dentsu-font-size-24: 1.5rem; /* 24px */
  --dentsu-font-size-22: 1.375rem; /* 22px */
  --dentsu-font-size-20: 1.25rem; /* 20px */
  --dentsu-font-size-18: 1.125rem; /* 18px */
  --dentsu-font-size-16: 1rem; /* 16px */
  --dentsu-font-size-14: 0.875rem; /* 14px */
  --dentsu-font-size-13: 0.8125rem; /* 13px */
  --dentsu-font-size-12: 0.75rem; /* 12px */
  --dentsu-font-size-11: 0.6875rem; /* 11px */
  --dentsu-font-size-10: 0.625rem; /* 10px */
  --dentsu-font-family-zh-hans: Inter, 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; /* Chinese (Simplified) */
  --dentsu-font-family-zh-hant: Inter, 'PingFang TC', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; /* Chinese (Traditional) */
  --dentsu-font-family-ko: Inter, 'Nanum Gothic', 'Apple SD Gothic Neo', 'Malgun Gothic', Roboto, Noto, 'Noto Sans CJK KR', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; /* Korean */
  --dentsu-font-family-ja: Inter, '游ゴシック体', YuGothic, 'ヒラギノ丸ゴ', 'Hiragino Sans', 'Yu Gothic UI', 'Meiryo UI', 'MS Gothic', Roboto, Noto, 'Noto Sans CJK JP', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; /* Japanese */
  --dentsu-font-family-ar: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; /* Arabic */
  --dentsu-font-family-mono: Menlo, 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --dentsu-font-family-serif: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Symbola', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --dentsu-font-family-sans: Inter, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantrell, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --dentsu-spacing-160: 10rem; /* 160px */
  --dentsu-spacing-96: 6rem; /* 96px */
  --dentsu-spacing-80: 5rem; /* 80px */
  --dentsu-spacing-64: 4rem; /* 64px */
  --dentsu-spacing-60: 3.75rem; /* 60px */
  --dentsu-spacing-56: 3.5rem; /* 56px */
  --dentsu-spacing-52: 3.25rem; /* 52px */
  --dentsu-spacing-48: 3rem; /* 48px */
  --dentsu-spacing-44: 2.75rem; /* 44px */
  --dentsu-spacing-40: 2.5rem; /* 40px */
  --dentsu-spacing-36: 2.25rem; /* 36px */
  --dentsu-spacing-32: 2rem; /* 32px */
  --dentsu-spacing-28: 1.75rem; /* 28px */
  --dentsu-spacing-24: 1.5rem; /* 24px */
  --dentsu-spacing-20: 1.25rem; /* 20px */
  --dentsu-spacing-16: 1rem; /* 16px */
  --dentsu-spacing-12: 0.75rem; /* 12px */
  --dentsu-spacing-8: 0.5rem; /* 8px */
  --dentsu-spacing-4: 0.25rem; /* 4px */
  --dentsu-spacing-2: 0.125rem; /* 2px */
  --dentsu-shadow-color: rgba(5,5,30,0.15);
  --dentsu-screen-xxxlarge: (min-width: 120em); /* 1920px and above */
  --dentsu-screen-xxlarge: (min-width: 100em); /* 1600px and above */
  --dentsu-screen-xlarge: (min-width: 75em); /* 1280px and above */
  --dentsu-screen-large: (min-width: 62em); /* 1024px and above */
  --dentsu-screen-medium: (min-width: 48em); /* 768px and above */
  --dentsu-screen-small: (min-width: 36em); /* 640px and above */
  --dentsu-screen-xsmall: (max-width: 39.9375em); /* 0-639px */
  --dentsu-screen-xxsmall: (max-width: 22.5em); /* 0-360px */
  --dentsu-radius-rounded: 9999px; /* 9999px */
  --dentsu-radius-24: 1.5rem; /* 24px */
  --dentsu-radius-16: 1rem; /* 16px */
  --dentsu-radius-12: 0.75rem; /* 12px */
  --dentsu-radius-8: 0.5rem; /* 8px */
  --dentsu-radius-6: 0.375rem; /* 6px */
  --dentsu-radius-4: 0.25rem; /* 4px */
  --dentsu-radius-2: 0.125rem; /* 2px */
  --dentsu-radius-0: 0; /* 0px */
  --dentsu-opacity-100: 1;
  --dentsu-opacity-95: 0.95;
  --dentsu-opacity-90: 0.9;
  --dentsu-opacity-80: 0.8;
  --dentsu-opacity-70: 0.7;
  --dentsu-opacity-60: 0.6;
  --dentsu-opacity-50: 0.5;
  --dentsu-opacity-40: 0.4;
  --dentsu-opacity-30: 0.3;
  --dentsu-opacity-25: 0.25;
  --dentsu-opacity-20: 0.2;
  --dentsu-opacity-10: 0.1;
  --dentsu-opacity-5: 0.05;
  --dentsu-opacity-0: 0;
  --dentsu-motion-duration-slowest: 700ms;
  --dentsu-motion-duration-slow: 400ms;
  --dentsu-motion-duration-moderate: 240ms;
  --dentsu-motion-duration-fast: 110ms;
  --dentsu-motion-duration-fastest: 70ms;
  --dentsu-motion-easing-exit: cubic-bezier(0.4, 0.14, 1, 1);
  --dentsu-motion-easing-entrance: cubic-bezier(0, 0, 0.3, 1);
  --dentsu-motion-easing-standard: cubic-bezier(0.4, 0.14, 0.3, 1);
  --dentsu-color-y1000: #050402;
  --dentsu-color-y975: #0d0b05;
  --dentsu-color-y950: #19170b;
  --dentsu-color-y900: #332f16;
  --dentsu-color-y850: #4c4621;
  --dentsu-color-y800: #665e2d;
  --dentsu-color-y750: #7f7538;
  --dentsu-color-y700: #998d44;
  --dentsu-color-y650: #b2a44f;
  --dentsu-color-y600: #ccbc5b;
  --dentsu-color-y550: #e5d366;
  --dentsu-color-y500: #ffeb72;
  --dentsu-color-y450: #ffed80;
  --dentsu-color-y400: #ffef8e;
  --dentsu-color-y350: #fff19c;
  --dentsu-color-y300: #fff3aa;
  --dentsu-color-y250: #fff5b8;
  --dentsu-color-y200: #fff7c6;
  --dentsu-color-y150: #fff9d4;
  --dentsu-color-y100: #fffbe2;
  --dentsu-color-y50: #fffdf1;
  --dentsu-color-y25: #fffdf7;
  --dentsu-color-y0: #fffefc;
  --dentsu-color-t1000: #000304;
  --dentsu-color-t975: #000a0a;
  --dentsu-color-t950: #001314;
  --dentsu-color-t900: #002829;
  --dentsu-color-t850: #003c3d;
  --dentsu-color-t800: #005052;
  --dentsu-color-t750: #006467;
  --dentsu-color-t700: #00797c;
  --dentsu-color-t650: #008d90;
  --dentsu-color-t600: #00a1a5;
  --dentsu-color-t550: #00b5b9;
  --dentsu-color-t500: #00cacf;
  --dentsu-color-t450: #1acfd3;
  --dentsu-color-t400: #33d4d8;
  --dentsu-color-t350: #4ddadd;
  --dentsu-color-t300: #66dfe2;
  --dentsu-color-t250: #80e4e7;
  --dentsu-color-t200: #99e9eb;
  --dentsu-color-t150: #b3eff0;
  --dentsu-color-t100: #ccf4f5;
  --dentsu-color-t50: #e6f9fa;
  --dentsu-color-t25: #f2fcfc;
  --dentsu-color-t0: #fafdfe;
  --dentsu-color-e1000: #040101;
  --dentsu-color-e975: #0c0304;
  --dentsu-color-e950: #170609;
  --dentsu-color-e900: #300c12;
  --dentsu-color-e850: #48121c;
  --dentsu-color-e800: #611925;
  --dentsu-color-e750: #791f2e;
  --dentsu-color-e700: #922538;
  --dentsu-color-e650: #aa2b41;
  --dentsu-color-e600: #c3324b;
  --dentsu-color-e550: #db3854;
  --dentsu-color-e500: #f43f5e;
  --dentsu-color-e450: #f5526e;
  --dentsu-color-e400: #f6657e;
  --dentsu-color-e350: #f7788e;
  --dentsu-color-e300: #f88b9e;
  --dentsu-color-e250: #f99fae;
  --dentsu-color-e200: #fab2be;
  --dentsu-color-e150: #fbc5cf;
  --dentsu-color-e100: #fcd8de;
  --dentsu-color-e50: #fdecef;
  --dentsu-color-e25: #fef5f6;
  --dentsu-color-e0: #fefbfb;
  --dentsu-color-r1000: #040000;
  --dentsu-color-r975: #0b0201;
  --dentsu-color-r950: #150403;
  --dentsu-color-r900: #2c0907;
  --dentsu-color-r850: #410e0a;
  --dentsu-color-r800: #58120e;
  --dentsu-color-r750: #6d1711;
  --dentsu-color-r700: #841c15;
  --dentsu-color-r650: #992018;
  --dentsu-color-r600: #b0251c;
  --dentsu-color-r550: #c52a1f;
  --dentsu-color-r500: #dc2f23;
  --dentsu-color-r450: #df4439;
  --dentsu-color-r400: #e3584f;
  --dentsu-color-r350: #e66d65;
  --dentsu-color-r300: #ea827b;
  --dentsu-color-r250: #ed9791;
  --dentsu-color-r200: #f1aba7;
  --dentsu-color-r150: #f4c1bd;
  --dentsu-color-r100: #f9e0de;
  --dentsu-color-r50: #fbeae9;
  --dentsu-color-r25: #fdf4f3;
  --dentsu-color-r0: #fefafa;
  --dentsu-color-p1000: #010003;
  --dentsu-color-p975: #040109;
  --dentsu-color-p950: #080213;
  --dentsu-color-p900: #120527;
  --dentsu-color-p850: #1b073a;
  --dentsu-color-p800: #240a4e;
  --dentsu-color-p750: #2d0c61;
  --dentsu-color-p700: #360f75;
  --dentsu-color-p650: #3f1188;
  --dentsu-color-p600: #48149c;
  --dentsu-color-p550: #5116b0;
  --dentsu-color-p500: #5b19c4;
  --dentsu-color-p450: #6b30ca;
  --dentsu-color-p400: #7b47cf;
  --dentsu-color-p350: #8c5ed5;
  --dentsu-color-p300: #9c75db;
  --dentsu-color-p250: #ad8ce1;
  --dentsu-color-p200: #bda3e7;
  --dentsu-color-p150: #cebaed;
  --dentsu-color-p100: #ded1f3;
  --dentsu-color-p50: #eee8f9;
  --dentsu-color-p25: #f6f3fb;
  --dentsu-color-p0: #fbfafd;
  --dentsu-color-k1000: #040103;
  --dentsu-color-k975: #0c0307;
  --dentsu-color-k950: #17070f;
  --dentsu-color-k900: #200926;
  --dentsu-color-k850: #46152d;
  --dentsu-color-k800: #5e1c3d;
  --dentsu-color-k750: #75234c;
  --dentsu-color-k700: #8d2b5b;
  --dentsu-color-k650: #a4326a;
  --dentsu-color-k600: #bc397a;
  --dentsu-color-k550: #d34089;
  --dentsu-color-k500: #ec4899;
  --dentsu-color-k450: #ed5aa3;
  --dentsu-color-k400: #ef6cad;
  --dentsu-color-k350: #f17fb7;
  --dentsu-color-k300: #f391c1;
  --dentsu-color-k250: #f5a3cc;
  --dentsu-color-k200: #f7b5d6;
  --dentsu-color-k150: #f9c8e0;
  --dentsu-color-k100: #fbdaea;
  --dentsu-color-k50: #fdedf5;
  --dentsu-color-k25: #fef5f9;
  --dentsu-color-k0: #fefbfd;
  --dentsu-color-o1000: #040200;
  --dentsu-color-o975: #0c0501;
  --dentsu-color-o950: #180b02;
  --dentsu-color-o900: #311704;
  --dentsu-color-o850: #4a2206;
  --dentsu-color-o800: #632e08;
  --dentsu-color-o750: #7c390a;
  --dentsu-color-o700: #95450d;
  --dentsu-color-o650: #ad500f;
  --dentsu-color-o600: #c75c11;
  --dentsu-color-o550: #df6713;
  --dentsu-color-o500: #f97316;
  --dentsu-color-o450: #f9812d;
  --dentsu-color-o400: #fa8f44;
  --dentsu-color-o350: #fa9d5c;
  --dentsu-color-o300: #fbab73;
  --dentsu-color-o250: #fcb98a;
  --dentsu-color-o200: #fcc7a1;
  --dentsu-color-o150: #fdd5b9;
  --dentsu-color-o100: #fde3d0;
  --dentsu-color-o50: #fef1e8;
  --dentsu-color-o25: #fef7f3;
  --dentsu-color-o0: #fefcfa;
  --dentsu-color-n1000: #05051e;
  --dentsu-color-n975: #131319;
  --dentsu-color-n950: #1c1c25;
  --dentsu-color-n900: #262632;
  --dentsu-color-n850: #343444;
  --dentsu-color-n800: #434357;
  --dentsu-color-n750: #51516a;
  --dentsu-color-n700: #60607d;
  --dentsu-color-n650: #7f7f97;
  --dentsu-color-n600: #9090a4;
  --dentsu-color-n550: #9f9fb1;
  --dentsu-color-n500: #aeaebc;
  --dentsu-color-n450: #b6b6c2;
  --dentsu-color-n400: #bebec9;
  --dentsu-color-n350: #c6c6d0;
  --dentsu-color-n300: #ceced6;
  --dentsu-color-n250: #d6d6dd;
  --dentsu-color-n200: #dedee4;
  --dentsu-color-n150: #e5e5e9;
  --dentsu-color-n100: #eeeef1;
  --dentsu-color-n50: #f7f7f8;
  --dentsu-color-n25: #fafafb;
  --dentsu-color-n0: #ffffff;
  --dentsu-color-l1000: #030401;
  --dentsu-color-l975: #080b02;
  --dentsu-color-l950: #0f1605;
  --dentsu-color-l900: #202e0a;
  --dentsu-color-l850: #30440f;
  --dentsu-color-l800: #415c15;
  --dentsu-color-l750: #51721a;
  --dentsu-color-l700: #618a1f;
  --dentsu-color-l650: #71a024;
  --dentsu-color-l600: #82b82a;
  --dentsu-color-l550: #92ce2f;
  --dentsu-color-l500: #a3e635;
  --dentsu-color-l450: #ace849;
  --dentsu-color-l400: #b5eb5d;
  --dentsu-color-l350: #beed71;
  --dentsu-color-l300: #c7f085;
  --dentsu-color-l250: #d1f29a;
  --dentsu-color-l200: #daf5ae;
  --dentsu-color-l150: #e3f7c2;
  --dentsu-color-l100: #ecfad6;
  --dentsu-color-l50: #f5fceb;
  --dentsu-color-l25: #fafdf4;
  --dentsu-color-l0: #fdfefb;
  --dentsu-color-g1000: #010402;
  --dentsu-color-g975: #030a06;
  --dentsu-color-g950: #06140c;
  --dentsu-color-g900: #0c2a1a;
  --dentsu-color-g850: #123f27;
  --dentsu-color-g800: #185434;
  --dentsu-color-g750: #1e6941;
  --dentsu-color-g700: #257f4e;
  --dentsu-color-g650: #2b935b;
  --dentsu-color-g600: #31a968;
  --dentsu-color-g550: #37be75;
  --dentsu-color-g500: #3ed483;
  --dentsu-color-g450: #51d88f;
  --dentsu-color-g400: #64dc9b;
  --dentsu-color-g350: #78e0a8;
  --dentsu-color-g300: #8be5b4;
  --dentsu-color-g250: #9ee9c1;
  --dentsu-color-g200: #b1edcd;
  --dentsu-color-g150: #c5f2da;
  --dentsu-color-g100: #d8f6e6;
  --dentsu-color-g50: #ecfaf2;
  --dentsu-color-g25: #f5fcf8;
  --dentsu-color-g0: #fbfefc;
  --dentsu-color-f1000: #030003;
  --dentsu-color-f975: #080209;
  --dentsu-color-f950: #0f0412;
  --dentsu-color-f900: #200926;
  --dentsu-color-f850: #300d39;
  --dentsu-color-f800: #41124d;
  --dentsu-color-f750: #511660;
  --dentsu-color-f700: #611b73;
  --dentsu-color-f650: #711f86;
  --dentsu-color-f600: #82249a;
  --dentsu-color-f550: #9228ad;
  --dentsu-color-f500: #a32dc1;
  --dentsu-color-f450: #ac42c7;
  --dentsu-color-f400: #b557cd;
  --dentsu-color-f350: #be6cd3;
  --dentsu-color-f300: #c781d9;
  --dentsu-color-f250: #d196e0;
  --dentsu-color-f200: #daabe6;
  --dentsu-color-f150: #e3c0ec;
  --dentsu-color-f100: #ecd5f2;
  --dentsu-color-f50: #f5eaf8;
  --dentsu-color-f25: #faf4fb;
  --dentsu-color-f0: #fdfafd;
  --dentsu-color-b1000: #000204;
  --dentsu-color-b975: #00050b;
  --dentsu-color-b950: #000a15;
  --dentsu-color-b900: #01152c;
  --dentsu-color-b850: #022042;
  --dentsu-color-b800: #022b59;
  --dentsu-color-b750: #03356f;
  --dentsu-color-b700: #044085;
  --dentsu-color-b650: #044b9b;
  --dentsu-color-b600: #0556b2;
  --dentsu-color-b550: #0660c8;
  --dentsu-color-b500: #076cdf;
  --dentsu-color-b450: #207ae2;
  --dentsu-color-b400: #3889e5;
  --dentsu-color-b350: #5198e8;
  --dentsu-color-b300: #6aa6eb;
  --dentsu-color-b250: #83b5ef;
  --dentsu-color-b200: #9bc4f2;
  --dentsu-color-b150: #b5d3f5;
  --dentsu-color-b100: #cde1f8;
  --dentsu-color-b50: #e6f0fb;
  --dentsu-color-b25: #f2f7fd;
  --dentsu-color-b0: #fafcfe;
  --dentsu-color-a1000: #050301;
  --dentsu-color-a975: #0d0703;
  --dentsu-color-a950: #190f06;
  --dentsu-color-a900: #331f0c;
  --dentsu-color-a850: #4c2e12;
  --dentsu-color-a800: #663e19;
  --dentsu-color-a750: #7f4d1f;
  --dentsu-color-a700: #995d25;
  --dentsu-color-a650: #b26c2b;
  --dentsu-color-a600: #cc7c32;
  --dentsu-color-a550: #e58b38;
  --dentsu-color-a500: #ff9b3f;
  --dentsu-color-a450: #ffa552;
  --dentsu-color-a400: #ffaf65;
  --dentsu-color-a350: #ffb978;
  --dentsu-color-a300: #ffc38b;
  --dentsu-color-a250: #ffcd9f;
  --dentsu-color-a200: #ffd7b2;
  --dentsu-color-a150: #ffe1c5;
  --dentsu-color-a100: #fff0e2;
  --dentsu-color-a50: #fff5ec;
  --dentsu-color-a25: #fff9f5;
  --dentsu-color-a0: #fffdfb;
  --dentsu-shadow-24: 0 12px 24px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-22: 0 11px 22px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-20: 0 10px 20px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-18: 0 9px 18px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-16: 0 8px 16px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-14: 0 7px 14px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-12: 0 6px 12px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-10: 0 5px 10px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-8: 0 4px 8px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-6: 0 3px 6px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-4: 0 2px 4px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-2: 0 1px 2px 0 var(--dentsu-shadow-color);
  --dentsu-shadow-1: 0 0 1px 0 var(--dentsu-shadow-color);
  --dentsu-scheme-purple-10: var(--dentsu-color-p900);
  --dentsu-scheme-purple-9: var(--dentsu-color-p800);
  --dentsu-scheme-purple-8: var(--dentsu-color-p700);
  --dentsu-scheme-purple-7: var(--dentsu-color-p600);
  --dentsu-scheme-purple-6: var(--dentsu-color-p500);
  --dentsu-scheme-purple-5: var(--dentsu-color-p400);
  --dentsu-scheme-purple-4: var(--dentsu-color-p350);
  --dentsu-scheme-purple-3: var(--dentsu-color-p250);
  --dentsu-scheme-purple-2: var(--dentsu-color-p150);
  --dentsu-scheme-purple-1: var(--dentsu-color-p50);
  --dentsu-scheme-category-12: var(--dentsu-color-f700);
  --dentsu-scheme-category-11: var(--dentsu-color-b700);
  --dentsu-scheme-category-10: var(--dentsu-color-f200);
  --dentsu-scheme-category-9: var(--dentsu-color-t800);
  --dentsu-scheme-category-8: var(--dentsu-color-p800);
  --dentsu-scheme-category-7: var(--dentsu-color-p500);
  --dentsu-scheme-category-6: var(--dentsu-color-t200);
  --dentsu-scheme-category-5: var(--dentsu-color-b550);
  --dentsu-scheme-category-4: var(--dentsu-color-t600);
  --dentsu-scheme-category-3: var(--dentsu-color-f500);
  --dentsu-scheme-category-2: var(--dentsu-color-p350);
  --dentsu-scheme-category-1: var(--dentsu-color-b300);
  --dentsu-scheme-blue-10: var(--dentsu-color-b800);
  --dentsu-scheme-blue-9: var(--dentsu-color-b700);
  --dentsu-scheme-blue-8: var(--dentsu-color-b600);
  --dentsu-scheme-blue-7: var(--dentsu-color-b550);
  --dentsu-scheme-blue-6: var(--dentsu-color-b500);
  --dentsu-scheme-blue-5: var(--dentsu-color-b400);
  --dentsu-scheme-blue-4: var(--dentsu-color-b300);
  --dentsu-scheme-blue-3: var(--dentsu-color-b200);
  --dentsu-scheme-blue-2: var(--dentsu-color-b100);
  --dentsu-scheme-blue-1: var(--dentsu-color-b50);
  --dentsu-color-pink: var(--dentsu-color-k500);
  --dentsu-color-rose: var(--dentsu-color-e500);
  --dentsu-color-turquoise: var(--dentsu-color-t500);
  --dentsu-color-lime: var(--dentsu-color-l500);
  --dentsu-color-yellow: var(--dentsu-color-y500);
  --dentsu-color-purple: var(--dentsu-color-p500);
  --dentsu-color-green: var(--dentsu-color-g500);
  --dentsu-color-fuchsia: var(--dentsu-color-f500);
  --dentsu-color-blue: var(--dentsu-color-b500);
  --dentsu-color-orange: var(--dentsu-color-o500);
  --dentsu-color-red: var(--dentsu-color-r500);
  --dentsu-color-amber: var(--dentsu-color-a500);
  --dentsu-color-white: var(--dentsu-color-n0);
  --dentsu-color-neutral: var(--dentsu-color-n500);
  --dentsu-color-grey: var(--dentsu-color-n700);
  --dentsu-color-gray: var(--dentsu-color-n700);
  --dentsu-color-black: var(--dentsu-color-n1000);
  --dentsu-shadow-inner: inset var(--dentsu-shadow-1);
}
