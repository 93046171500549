@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~overlayscrollbars/overlayscrollbars.css";
@import "../tokens/index.css";

@font-face {
    font-named-instance: "Regular";
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 100 900;
    src: url(~@dentsu-ui/elements/assets/fonts/inter.roman.var.woff2) format("woff2")
}

@font-face {
    font-named-instance: "Italic";
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 100 900;
    src: url(~@dentsu-ui/elements/assets/fonts/inter.italic.var.woff2) format("woff2")
}

body {
    font-feature-settings: "kern", "liga", "calt", "case", "ss03", "cv03", "cv04";
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    font-family: var(--dentsu-font-family-sans) !important;
    text-rendering: optimizeLegibility
}

body:lang(zh-hans) {
    font-family: var(--dentsu-font-family-zh-hans) !important
}

body:lang(zh-hant) {
    font-family: var(--dentsu-font-family-zh-hant) !important
}

body:lang(ja) {
    font-family: var(--dentsu-font-family-ja) !important
}

#__next,
#root,
body,
html {
    height: 100%
}

*,
:after,
:before {
    background-repeat: no-repeat;
    box-sizing: border-box
}

:after,
:before {
    text-decoration: inherit;
    vertical-align: inherit
}

:focus:not(:focus-visible) {
    outline: none !important
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    cursor: default
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px !important;
    margin: 0
}

main {
    display: block
}

nav ol,
nav ul {
    list-style: none
}

a {
    background-color: initial
}

abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: 600
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 400;
    margin: 0
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle
}

audio,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

img {
    border-style: none
}

svg:not([fill]) {
    fill: currentColor
}

svg:not(:root) {
    overflow: hidden
}

table {
    border-collapse: collapse
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input,
select {
    margin: 0
}

button {
    overflow: visible;
    text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

input {
    overflow: visible
}

legend {
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal
}

progress {
    display: inline-block;
    vertical-align: initial
}

select {
    text-transform: none
}

textarea {
    margin: 0;
    overflow: auto;
    resize: vertical
}

[type=checkbox],
[type=radio] {
    padding: 0
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: .54
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

::-moz-focus-inner {
    border-style: none;
    padding: 0
}

:-moz-focusring {
    outline: 1px dotted ButtonText
}

details,
dialog {
    display: block
}

dialog {
    background-color: #fff;
    border: solid;
    color: #000;
    height: -moz-fit-content;
    height: fit-content;
    left: 0;
    margin: auto;
    padding: 1em;
    position: absolute;
    right: 0;
    width: -moz-fit-content;
    width: fit-content
}

dialog:not([open]) {
    display: none
}

summary {
    display: list-item
}

canvas {
    display: inline-block
}

template {
    display: none
}

[tabindex],
a,
area,
button,
input,
label,
select,
summary,
textarea {
    touch-action: manipulation
}

[hidden] {
    display: none
}

[aria-busy=true] {
    cursor: progress
}

[aria-controls] {
    cursor: pointer
}

[aria-disabled=true],
[disabled] {
    cursor: not-allowed
}

[aria-hidden=false][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    display: inherit;
    position: absolute
}

.tippy-box[data-animation=shift-away][data-state=hidden] {
    opacity: 0
}

.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=top] {
    transform: translateY(10px)
}

.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=bottom] {
    transform: translateY(-10px)
}

.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=left] {
    transform: translateX(10px)
}

.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=right] {
    transform: translateX(-10px)
}

#hot-display-license-info {
    display: none
}

@keyframes react-loading-skeleton {
    to {
        transform: translateX(100%)
    }
}

.react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block;
    background-color: var(--base-color);
    border-radius: .25rem;
    display: inline-flex;
    line-height: 1;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;
    z-index: 1
}

.react-loading-skeleton:after {
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-iteration-count: infinite;
    animation-name: react-loading-skeleton;
    animation-timing-function: ease-in-out;
    background-image: linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color));
    background-repeat: no-repeat;
    content: " ";
    display: var(--pseudo-element-display);
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%)
}

@media (prefers-reduced-motion) {
    .react-loading-skeleton {
        --pseudo-element-display: none
    }
}

.bp4-input-group > .bp4-input-left-container > .bp4-icon, .bp4-input-group > .bp4-icon, .bp4-input-group .bp4-input-action > .bp4-spinner {
    margin: 0px !important;
}